// Copyright 2017-2025 @polkadot/react-components authors & contributors
// SPDX-License-Identifier: Apache-2.0

import type { KeyringItemType } from '@polkadot/ui-keyring/types';
import { getAddressMeta } from './getAddressMeta.js';
import { squidClient, GET_NAME_QUERY, GetNameResponse, AccountByPk } from '@polkadot/apps';

class AddressNameEmitter {
  private listeners: Map<string, Set<(name: string) => void>> = new Map();

  addListener(address: string, callback: (name: string) => void) {
    if (!this.listeners.has(address)) {
      this.listeners.set(address, new Set());
    }
    this.listeners.get(address)!.add(callback);
  }

  removeListener(address: string, callback: (name: string) => void) {
    const addressListeners = this.listeners.get(address);
    if (addressListeners) {
      addressListeners.delete(callback);
    }
  }

  emit(address: string, name: string) {
    const addressListeners = this.listeners.get(address);
    if (addressListeners) {
      addressListeners.forEach(callback => callback(name));
    }
  }
}

const addressNameEmitter = new AddressNameEmitter();

function getAddressName(address: string, type: KeyringItemType | null = null, defaultName?: string): [boolean, boolean, string] {
  const meta = getAddressMeta(address, type);

  if (meta.name) {
    return [false, false, meta.name];
  }

  if (defaultName) {
    return [false, true, defaultName];
  }

  // Check the cache
  const cachedName = checkCache(address);
  if (cachedName) {
    return [false, false, cachedName];
  }

  // If not in cache, trigger the asynchronous request
  fetchNameFromGraphQL(address);

  return [true, false, address];
}

function checkCache(address: string): string | null {
  try {
    const cachedResult = squidClient.readQuery<GetNameResponse>({
      query: GET_NAME_QUERY,
      variables: { id: address }
    });
    return cachedResult?.accountByPk?.identity?.name || null;
  } catch (error) {
    console.error('Error reading from cache:', error);
    return null;
  }
}

async function fetchNameFromGraphQL(address: string): Promise<void> {
  try {
    const { data } = await squidClient.query<GetNameResponse>({
      query: GET_NAME_QUERY,
      variables: { id: address },
      fetchPolicy: 'network-only'
    });

    let name: string | null = null;
    let cacheData: AccountByPk | null = null;
    let isWasIdentity = false;

    if (data.accountByPk?.identity?.name) {
      name = data.accountByPk.identity.name;
      cacheData = data.accountByPk;
    } else if (data.accountByPk?.wasIdentity?.[0]?.next?.identity?.name) {
      name = data.accountByPk.wasIdentity[0].next.identity.name;
      isWasIdentity = true;
      cacheData = {
        id: address,
        identity: {
          name: `${name} (oldAddress)`
        },
        wasIdentity: []
      };
    }

    if (cacheData) {
      // Update the cache with the new data
      updateCache(address, cacheData);
    }

    if (name) {
      const displayName = isWasIdentity ? `${name} (oldAddress)` : name;
      addressNameEmitter.emit(address, displayName);
    }
  } catch (error) {
    console.error('Erreur, impossible d\'obtenir le nom depuis squid:', error);
  }
}


function updateCache(address: string, data: AccountByPk) {
  squidClient.cache.writeQuery<GetNameResponse>({
    query: GET_NAME_QUERY,
    variables: { id: address },
    data: { accountByPk: data }
  });
}

export { getAddressName, addressNameEmitter };
