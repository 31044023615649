// Copyright 2017-2025 @polkadot/react-query authors & contributors
// SPDX-License-Identifier: Apache-2.0

import type { AccountId, AccountIndex, Address } from '@polkadot/types/interfaces';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { statics } from '@polkadot/react-api/statics';
import { useApi, useDeriveAccountInfo } from '@polkadot/react-hooks';
import { AccountSidebarCtx } from '@polkadot/react-hooks/ctx/AccountSidebar';
import { formatNumber, isCodec, isFunction, isU8a, stringToU8a, u8aEmpty, u8aEq, u8aToBn } from '@polkadot/util';
import { decodeAddress } from '@polkadot/util-crypto';

import { getAddressName, addressNameEmitter } from './util/index.js';
import { styled } from './styled.js';

interface Props {
  children?: React.ReactNode;
  className?: string;
  defaultName?: string;
  label?: React.ReactNode;
  onClick?: () => void;
  override?: React.ReactNode;
  toggle?: unknown;
  value: AccountId | AccountIndex | Address | string | Uint8Array | null | undefined;
  withSidebar?: boolean;
}
type AddrMatcher = (addr: unknown) => string | null;

function createAllMatcher (prefix: string, name: string): AddrMatcher {
  const test = statics.registry.createType('AccountId', stringToU8a(prefix.padEnd(32, '\0')));

  return (addr: unknown) =>
    test.eq(addr)
      ? name
      : null;
}

function createNumMatcher (prefix: string, name: string, add?: string): AddrMatcher {
  const test = stringToU8a(prefix);

  // 4 bytes for u32 (more should not hurt, LE)
  const minLength = test.length + 4;

  return (addr: unknown): string | null => {
    try {
      const decoded = isU8a(addr) ? addr : isCodec(addr) ? addr.toU8a() : decodeAddress(addr?.toString() || '');
      const type = decoded.length === 20 ? 'AccountId20' : 'AccountId';
      const u8a = statics.registry.createType(type, decoded).toU8a();

      return (u8a.length >= minLength) && u8aEq(test, u8a.subarray(0, test.length)) && u8aEmpty(u8a.subarray(minLength))
        ? `${name} ${formatNumber(u8aToBn(u8a.subarray(test.length, minLength)))}${add ? ` (${add})` : ''}`
        : null;
    } catch (e) {
      console.log(e);

      return null;
    }
  };
}

const MATCHERS: AddrMatcher[] = [
  createAllMatcher('modlpy/socie', 'Society'),
  createAllMatcher('modlpy/trsry', 'Treasury'),
  createAllMatcher('modlpy/xcmch', 'XCM'),
  createNumMatcher('modlpy/cfund', 'Crowdloan'),
  // Substrate master
  createNumMatcher('modlpy/npols\x00', 'Pool', 'Stash'),
  createNumMatcher('modlpy/npols\x01', 'Pool', 'Reward'),
  // Westend
  createNumMatcher('modlpy/nopls\x00', 'Pool', 'Stash'),
  createNumMatcher('modlpy/nopls\x01', 'Pool', 'Reward'),
  createNumMatcher('para', 'Child'),
  createNumMatcher('sibl', 'Sibling')
];

const displayCache = new Map<string, React.ReactNode>();
const indexCache = new Map<string, string>();
const parentCache = new Map<string, string>();

export function getParentAccount(value: string): string | undefined {
  return parentCache.get(value);
}

function defaultOrAddr(defaultName = '', _address: AccountId | AccountIndex | Address | string | Uint8Array, _accountIndex?: AccountIndex | null): [displayName: React.ReactNode, isLocal: boolean, isAddress: boolean, isSpecial: boolean] {
  let known: string | null = null;

  for (let i = 0; known === null && i < MATCHERS.length; i++) {
    known = MATCHERS[i](_address);
  }

  if (known) {
    return [known, false, false, true];
  }

  const accountId = _address.toString();

  if (!accountId) {
    return [defaultName, false, false, false];
  }

  const [isAddressExtracted, , extracted] = getAddressName(accountId, null, defaultName);
  const accountIndex = (_accountIndex || '').toString() || indexCache.get(accountId);

  if (isAddressExtracted && accountIndex) {
    indexCache.set(accountId, accountIndex);

    return [accountIndex, false, true, false];
  }

  return [extracted, !isAddressExtracted, isAddressExtracted, false];
}

function defaultOrAddrNode (defaultName = '', address: AccountId | AccountIndex | Address | string | Uint8Array, accountIndex?: AccountIndex | null): React.ReactNode {
  const [node, , isAddress] = defaultOrAddr(defaultName, address, accountIndex);

  return isAddress
    ? <span className='isAddress'>{node}</span>
    : node;
}

function extractName(address: string, accountIndex?: AccountIndex, defaultName?: string): React.ReactNode {
  const displayCached = displayCache.get(address);
  if (displayCached) {
    return displayCached;
  }

  const [displayName, isAddress] = defaultOrAddr(defaultName, address, accountIndex);
  

  return isAddress
    ? <span className='isAddress'>{displayName}</span>
    : displayName;
}

function AccountName({ children, className = '', defaultName, label, onClick, override, toggle, value, withSidebar }: Props): React.ReactElement {
  const { apiIdentity } = useApi();
  const info = useDeriveAccountInfo(value);
  const [name, setName] = useState<React.ReactNode>(() => extractName((value || '').toString(), undefined, defaultName));
  const toggleSidebar = useContext(AccountSidebarCtx);

  useEffect((): void => {
    const { accountId, accountIndex, identity, nickname } = info || {};
    const cacheAddr = (accountId || value || '').toString();

    if (identity?.parent) {
      parentCache.set(cacheAddr, identity.parent.toString());
    }

    if (apiIdentity && isFunction(apiIdentity.query.identity?.identityOf)) {
      setName(() => extractName(cacheAddr, accountIndex)
      );
    } else if (nickname) {
      setName(nickname);
    } else {
      setName(defaultOrAddrNode(defaultName, cacheAddr, accountIndex));
    }
  }, [apiIdentity, defaultName, info, toggle, value]);

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const handleNameUpdate = (updatedName: string) => {
      const newName = extractName((value || '').toString(), undefined, updatedName);
      
      // Check if the new name is different from the current one
      if (newName !== name) {
        setIsUpdating(true);
        setTimeout(() => {
          setName(newName);
          setIsUpdating(false);
        }, 150); // fade out duration
      }
    };
  
    if (value) {
      addressNameEmitter.addListener(value.toString(), handleNameUpdate);
    }
  
    return () => {
      if (value) {
        addressNameEmitter.removeListener(value.toString(), handleNameUpdate);
      }
    };
  }, [value, defaultName, name]);

  const _onNameEdit = useCallback(
    () => setName(defaultOrAddrNode(defaultName, (value || '').toString())),
    [defaultName, value]
  );

  const _onToggleSidebar = useCallback(
    () => toggleSidebar && value && toggleSidebar([value.toString(), _onNameEdit]),
    [_onNameEdit, toggleSidebar, value]
  );

  return (
    <StyledSpan
      className={`${className} ${withSidebar ? 'withSidebar' : ''} ${isUpdating ? 'updating' : ''}`}
      onClick={withSidebar ? _onToggleSidebar : onClick}
    >
      {label || ''}{override || name}{children}
    </StyledSpan>
  );
}

const StyledSpan = styled.span`
  border: 1px dotted transparent;
  line-height: 1.2;
  vertical-align: middle;
  white-space: nowrap;
  transition: opacity 0.15s ease-in-out;

  &.withSidebar:hover {
    border-bottom-color: #333;
    cursor: help !important;
  }

  &.updating {
    opacity: 0;
  }

  .isAddress {
    display: inline-block;
    min-width: var(--width-shortname);
    max-width: var(--width-shortname);
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
  }

  .via-identity {
    word-break: break-all;

    .name {
      font-weight: var(--font-weight-normal) !important;
      filter: grayscale(100%);
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(.isAddress) {
        text-transform: uppercase;
      }

      &.isAddress {
        opacity: var(--opacity-light);
      }

      .sub,
      .top {
        vertical-align: middle;
      }

      .sub {
        font-size: var(--font-size-tiny);
        opacity: var(--opacity-light);
      }
    }
  }
`;


export default React.memo(AccountName);